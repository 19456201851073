import React from 'react'
import ReactDOM from 'react-dom/client'
import { PublishRoot } from './components/PublishRoot'
import { env } from '~publish/env'

window.API_GATEWAY_URL = env.VITE_GRAPHQL_API
window.VERSION =
  env.VITE_NODE_ENV === 'development' ? 'local' : env.VITE_COMMIT_HASH
// used in async-data-fetch to pass as a header to track in DD custom metrics
window.xBufferClientId = env.VITE_X_BUFFER_CLIENT_ID

const rootEl = document.getElementById('root')
if (rootEl) {
  ReactDOM.createRoot(rootEl).render(<PublishRoot />)
} else {
  console.error('Could not find root element to mount publish-frontend.')
}
