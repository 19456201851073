import React from 'react'
import { withRouter } from 'react-router-dom'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import LegacyNotifications from '@buffer-mono/legacy-bufferapp-notifications'

import { AppPages } from '~publish/legacy/app-pages'
import { AppModals } from '~publish/legacy/modals'
import { Notifications } from '~publish/legacy/notifications-provider/components/Notifications'
import TemporaryBanner from '~publish/legacy/temporary-banner'
import ThirdParty from '~publish/legacy/thirdParty'
import { WelcomeChecklistContainer } from '~publish/legacy/welcome-checklist/components/WelcomeChecklistContainer'
import ComposerPopover from '~publish/legacy/composer-popover'
import { OptedOutBanner } from '~publish/legacy/date-time-preferences/components/NewPublishExperience/OptedOutBanner'
import { ChannelTour } from '~publish/pages/Channel/ChannelTour'
import { useShouldShowTrialPromptModal } from '~publish/hooks/useShouldShowTrialPromptModal'
import { SplitProtected } from '~publish/components/SplitProtected'
import { BannerActive } from '~publish/components/BannerActive'
import { useAccount } from '~publish/legacy/accountContext'

import LoadingGate from '../LoadingGate'
import { useShowInstagramPersonalProfilesAnnouncementDialog } from '~publish/hooks/useShowInstagramPersonalProfilesAnnouncementDialog'
import { useMixpanelSessionReplay } from '~publish/hooks/useMixpanelSessionReplay'

const ThirdPartyWithRouter = withRouter(ThirdParty)
const AppPagesWithRouter = withRouter(AppPages as any)

const appStyle = {
  display: 'flex',
  height: '100%',
}

const contentStyle = {
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column' as const,
  width: '100vw',
  // TODO: we should refactor layout styles here
  // so the there is ability to use full page scroll
  overflow: 'hidden',
}

// Can't use stateless function for App since then
// the `DragDropContext` doesn't work.
const App = (): JSX.Element => {
  useShouldShowTrialPromptModal()
  const { account } = useAccount()
  const signUpDate = account?.createdAt
    ? new Date(account?.createdAt).getTime()
    : undefined
  useShowInstagramPersonalProfilesAnnouncementDialog()

  useMixpanelSessionReplay()

  return (
    <div style={appStyle} className="notranslate app-root">
      <div style={contentStyle}>
        <LoadingGate>
          <OptedOutBanner />
          <TemporaryBanner />
          <ComposerPopover />
          <AppPagesWithRouter />
          <WelcomeChecklistContainer />
          {/* TODO: remove this when we are ready to remove the tour */}
          <SplitProtected name="channels-tour" attributes={{ signUpDate }}>
            <BannerActive name="publish-revamp-page-tour">
              <ChannelTour />
            </BannerActive>
          </SplitProtected>
        </LoadingGate>
      </div>
      <Notifications />
      <LegacyNotifications />
      <AppModals />
      <ThirdPartyWithRouter />
    </div>
  )
}

export default DragDropContext(HTML5Backend)(App)
