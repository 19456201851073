import { useSplitEnabled } from '@buffer-mono/features'
import { useAccount } from '~publish/legacy/accountContext'
import { isTrialUser } from '~publish/helpers/user'
import { BannerTypes } from '~publish/legacy/profile-page/hooks/useDismissBanner'
import { subDays } from 'date-fns'

export function useShowInstagramPersonalProfilesAnnouncementDialog(): void {
  const { isEnabled: isInstagramRemindersPersonalProfilesEnabled } =
    useSplitEnabled('instagram-reminders-personal-profiles')
  const bannerName = BannerTypes.instagramPersonalProfilesAnnouncementDialog

  const { account } = useAccount()
  const accountCreatedAtDate = account?.createdAt
    ? new Date(account?.createdAt)
    : null
  // We're considering a new user to be an account created within last 14 days
  const isNewUser =
    accountCreatedAtDate && subDays(new Date(), 14) < accountCreatedAtDate
  const isOnTrial = isTrialUser(account)
  const hasDismissedBanner = account?.dismissedBanners?.includes(bannerName)

  const shouldNotDisplayDialog =
    !account ||
    !isInstagramRemindersPersonalProfilesEnabled ||
    hasDismissedBanner ||
    isOnTrial ||
    isNewUser

  if (shouldNotDisplayDialog) {
    return
  }

  const { MODALS, actions } = window?.appshell || {}
  if (actions) {
    actions.openModal(MODALS.instagramPersonalProfilesAnnouncement, {})
  }
}
